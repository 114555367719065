import {useState, useEffect, useCallback, useRef}from 'react';

//모듈 로드
export default function useEnuSpaceModule(){
    const [enuSpace, setEnuSpace] = useState('');
    const timeoutIdRef = useRef(null);

    const getEnuSpaceModule = useCallback((Count = 0) => {
        if (window.g_enuSpace === undefined) {
            timeoutIdRef.current = setTimeout(() => {
                if (Count === 2) {
                    window.ReModalInit();
                }

                if (Count > 4) {
                    alert(`(모듈 로드 실패) 새로고침해 주세요.`);
                    return;
                }

                if (window.g_enuSpace === undefined) {
                    getEnuSpaceModule(++Count);
                } else {
                    setEnuSpace(window.g_enuSpace);
                    window.g_enuSpace.StartLoop();
                }
            }, 500);

            return () => clearTimeout(timeoutIdRef.current); // 반복 종료시 clearTimeout 호출
        } else {
            setEnuSpace(window.g_enuSpace);
            window.g_enuSpace.StartLoop();
        }

    }, []);

    useEffect(()=>{
        getEnuSpaceModule();
    }, [getEnuSpaceModule]);

    useEffect(()=>{
        return () =>{
            clearTimeout(timeoutIdRef.current);
        }
    }, [timeoutIdRef]);

    const StartLoop = () =>{
        if(enuSpace === '')
            return;
            
        enuSpace.StartLoop();
    }

    return [enuSpace, StartLoop]
}