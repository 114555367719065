(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@babylonjs/core"), require("@babylonjs/materials"), require("@babylonjs/core"), require("@babylonjs/gui"), require("@babylonjs/gui-editor"), require("@babylonjs/loaders"), require("@babylonjs/serializers"));
	else if(typeof define === 'function' && define.amd)
		define("@babylonjs/inspector", ["@babylonjs/core", "@babylonjs/materials", "@babylonjs/core", "@babylonjs/gui", "@babylonjs/gui-editor", "@babylonjs/loaders", "@babylonjs/serializers"], factory);
	else if(typeof exports === 'object')
		exports["@babylonjs/inspector"] = factory(require("@babylonjs/core"), require("@babylonjs/materials"), require("@babylonjs/core"), require("@babylonjs/gui"), require("@babylonjs/gui-editor"), require("@babylonjs/loaders"), require("@babylonjs/serializers"));
	else
		root["INSPECTOR"] = factory(root["BABYLON"], root["BABYLON"], root["BABYLON"]["Debug"], root["BABYLON"]["GUI"], root["BABYLON"], root["BABYLON"], root["BABYLON"]);
})((typeof self !== "undefined" ? self : typeof global !== "undefined" ? global : this), (__WEBPACK_EXTERNAL_MODULE_core_Misc_observable__, __WEBPACK_EXTERNAL_MODULE_materials_grid_gridMaterial__, __WEBPACK_EXTERNAL_MODULE_core_Debug_physicsViewer__, __WEBPACK_EXTERNAL_MODULE_gui_2D_adtInstrumentation__, __WEBPACK_EXTERNAL_MODULE_gui_editor_guiEditor__, __WEBPACK_EXTERNAL_MODULE_loaders_glTF_index__, __WEBPACK_EXTERNAL_MODULE_serializers_glTF_2_0_index__) => {
return 